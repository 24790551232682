<template>
    <OverlayPanel ref="op" class="w-22rem" dismissable>
        <div v-if="filtroDefault" class="flex flex-column">
            <label>Período:</label>
            <Dropdown
                v-model="periodicidade"
                :options="optionsPeriodicidade"
                class="mt-1"
                optionLabel="label"
                placeholder="Periodicidade"
                @update:model-value="alterarPeriodicidade"
            />
            <Calendar
                ref="calendar"
                v-model="periodo"
                :dateFormat="periodicidade.format"
                :disabledDays="[0, 6]"
                :minDate="!periodo?.[1] ? periodo?.[0] : null"
                :maxDate="hoje"
                :view="periodicidade.view"
                class="mt-3"
                selectionMode="range"
                showIcon
            />
        </div>

        <div v-if="filtroStatusConcluidos" class="flex flex-column mt-3">
            <label>Status:</label>
            <MultiSelect
                v-model="statusIds"
                :options="optionsStatusConcluidos"
                class="w-full mt-1"
                dataKey="id"
                filter
                optionLabel="label"
                optionValue="value"
                placeholder="Selecione um status..."
            />
        </div>
        <div v-if="filtroStatusEmAndamento" class="flex flex-column mt-3">
            <label>Status:</label>
            <MultiSelect
                v-model="statusIds"
                :options="optionsStatusEmAndamento"
                class="w-full mt-1 py-1"
                dataKey="id"
                filter
                optionLabel="label"
                optionValue="value"
                placeholder="Selecione um status..."
            />
        </div>
        <div v-if="filtroCidades" class="flex flex-column w-17rem mt-3">
            <label>Cidades:</label>
            <MultiSelectCidade v-model="cidadeIds" class="w-full mt-1" dataKey="id" />
        </div>
        <div class="text-right mt-3">
            <Button class="py-2" @click="filtrar">Filtrar</Button>
        </div>
    </OverlayPanel>
</template>

<script setup>
import { ref, defineEmits, defineProps, defineExpose, onMounted, nextTick, computed } from 'vue';
import StatusAgendamento from '@/enums/StatusAgendamento';
import MultiSelectCidade from '../../cidades/components/MultiSelectCidade.vue';
import moment from 'moment';

const emit = defineEmits(['onSave']);
defineProps({
    filtroCidades: {
        type: Boolean,
        default: false
    },
    filtroDefault: {
        tyoe: Boolean,
        default: true
    },
    filtroStatusConcluidos: {
        tyoe: Boolean,
        default: false
    },
    filtroStatusEmAndamento: {
        tyoe: Boolean,
        default: false
    }
});

const hoje = new Date();
const calendar = ref(null);
const cidadeIds = ref(null);
const op = ref(null);
const optionsPeriodicidade = [
    { label: 'Dia', view: 'date', value: 'day', format: 'dd/mm/yy' },
    { label: 'Mês', view: 'month', value: 'month', format: 'mm/yy' },
    { label: 'Ano', view: 'year', value: 'year', format: 'yy' }
];
const optionsStatusConcluidos = [
    { label: 'Cancelado', value: StatusAgendamento.CANCELADO },
    { label: 'Desistência de Vaga', value: StatusAgendamento.DESISTENCIA_DE_VAGA },
    { label: 'Falta Consulta', value: StatusAgendamento.FALTA_CONSULTA },
    { label: 'Concluído', value: StatusAgendamento.CONCLUIDO }
];
const optionsStatusEmAndamento = [
    { label: 'Em Aberto', value: StatusAgendamento.EM_ABERTO },
    { label: 'Em Andamento', value: StatusAgendamento.EM_ANDAMENTO },
    { label: 'Aguardando Conf. Colaborador', value: StatusAgendamento.AGUARDANDO_CONF_COLABORADOR },
    { label: 'Pendência Cliente', value: StatusAgendamento.PENDENCIA_CLIENTE },
    { label: 'Aguardando Data', value: StatusAgendamento.AGUARDANDO_DATA },
    { label: 'Dia da Consulta', value: StatusAgendamento.DIA_DA_CONSULTA },
    { label: 'Aguardando Conf. Comparecimento', value: StatusAgendamento.AGUARDANDO_CONF_COMPARECIMENTO },
    { label: 'Agendado', value: StatusAgendamento.AGENDADO },
    { label: 'Aguardando Aplicação Risco', value: StatusAgendamento.AGUARDANDO_APLICACAO_RISCO },
    { label: 'Processando Agendamento', value: StatusAgendamento.PROCESSANDO_AGENDAMENTO },
    { label: 'Pendência Resolvida', value: StatusAgendamento.PENDENCIA_RESOLVIDA },
    { label: 'Pendência Prestador', value: StatusAgendamento.PENDENCIA_PRESTADOR },
    { label: 'Depósito Antecipado', value: StatusAgendamento.DEPOSITO_ANTECIPADO }
];
const periodicidade = ref(optionsPeriodicidade.find((option) => option.value === 'month'));
const periodo = ref([moment(hoje).subtract(11, 'M').startOf('month').toDate(), hoje]);
const statusIds = ref(null);

const dataInicio = computed(() => {
    let data = moment.tz(periodo.value?.[0], 'UTC');
    if (periodicidade.value.value === 'month') {
        data = data.startOf('month');
    }
    if (periodicidade.value.value === 'year') {
        data = data.startOf('year');
    }

    return data.toDate();
});
const dataFim = computed(() => {
    let data = moment.tz(periodo.value?.[1], 'UTC');
    if (periodicidade.value.value === 'month') {
        data = data.endOf('month');
    }
    if (periodicidade.value.value === 'year') {
        data = data.endOf('year');
    }

    return moment(data).isAfter(hoje, 'day') ? hoje : data.toDate();
});

onMounted(() => filtrar());

function alterarPeriodicidade(periodicidade) {
    if (periodicidade.value.day) {
        periodo.value = [hoje];
    }

    if (periodicidade.value.month) {
        periodo.value = [moment(hoje).subtract(11, 'M').startOf('month').toDate(), hoje];
    }

    if (periodicidade.value.year) {
        const anoAtual = hoje.getFullYear();
        periodo.value = [anoAtual];
    }

    nextTick(() => {
        calendar.value.currentView = periodicidade?.view;
        calendar.value.input.value = calendar.value.formatValue(periodo.value);
    });
}
function filtrar(event) {
    const params = {
        periodicidade: periodicidade.value.value,
        dataInicio: dataInicio.value,
        dataFim: dataFim.value,
        statusIds: statusIds.value,
        cidadeIds: cidadeIds.value
    };
    emit('onSave', params);
    if (event) toggle(event);
}
function toggle(event) {
    op.value.toggle(event);
}

defineExpose({ toggle });
</script>
