<template>
    <AppMultiselect
        v-model="value"
        optionLabel="cidade"
        placeholder="Selecione as cidades..."
        :service="$service"
        :itemSize="itemSize"
        :renderOption="(slotProps) => concatenaCidadeComUf ? slotProps.option?.cidade + ' - ' + slotProps.option?.estado : slotProps.option?.cidade"
        :renderValue="(slotProps) => concatenaCidadeComUf ? slotProps.value?.cidade + ' - ' + slotProps.value?.estado : slotProps.value?.cidade"
    />
</template>
<script>
import BaseService from '@/services/BaseService'

export default {
    props: {
        modelValue: {
            type: Object
        },
        itemSize: {
            type: Number,
            default: 20
        }
    },    
    emits: ['update:modelValue'],
    data() {
        return {
            service: null
        }
    },
    computed: {
        value: {
            get() {
                return this.modelValue;
            },
            set(value) {
                this.$emit('update:modelValue', value);
            }
        }
    },
    created() {
        this.$service = new BaseService('/cidades')
    }
};
</script>
