<template>
    <div>
        <HeaderCard :loading="loading" tittle="E-mails com agendamentos" @onLoad="load" />
        <div class="flex flex-column">
            <p class="text-7xl font-normal m-0 mt-4 justify-content-center" style="line-height: 38px">
                {{ dados[0]?.percentualAbertos == undefined || dados[0]?.percentualAbertos == '0.00' ? '0%' : dados[0]?.percentualAbertos + '%' }}
            </p>
            <div class="m-0" style="top: 150px">
                <p class="text-md font-normal mb- justify-content-center">E-mails abertos com agendamentos futuros</p>
            </div>
        </div>
        <div class="flex" style="width: 100%">
            <Chart type="doughnut" :data="stackedData" :options="stackedOptions" class="h-20rem" />
            <div style="display: flex; flex-direction: column; justify-content: center; gap: 10px">
                <div>
                    <span style="font-size: 30px; font-weight: bold">{{ quantidadeTotal }} </span>
                    <p>Total de e-mails</p>
                </div>
                <div style="display: flex; flex-direction: column">
                    <span style="font-size: 30px; font-weight: bold">{{ quantidadeAbertos }}</span>
                    <div style="display: flex; align-items: center; gap: 5px">
                        <div style="background: #4bc0c0; width: 10px; height: 10px" />
                        <p>Abertos</p>
                    </div>
                </div>
                <div style="display: flex; flex-direction: column">
                    <span style="font-size: 30px; font-weight: bold">{{ quantidadeNaoAbertos }}</span>
                    <div style="display: flex; align-items: center; gap: 5px">
                        <div style="background: #ffcd56; width: 10px; height: 10px" />
                        <p>Não abertos</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getClientBase } from '@/services/http';
import HeaderCard from '../../components/HeaderCard.vue';
import formatarNumero from '@/utils/FormatadorNumero';
export default {
    data() {
        return {
            dados: [],
            periodicidade: null,
            dataInicio: null,
            dataFim: null,
            loading: false
        };
    },
    components: {
        HeaderCard
    },
    computed: {
        stackedData() {
            return {
                datasets: [
                    {
                        data: [this.dados[0]?.abertos, this.dados[0]?.naoAbertos],
                        backgroundColor: ['#4BC0C0', '#FFCD56']
                    }
                ]
            };
        },
        stackedOptions() {
            return {
                plugins: {
                    legend: {
                        labels: {
                            usePointStyle: true,
                            color: '#495057'
                        },
                        position: 'right'
                    }
                }
            };
        },
        quantidadeTotal() {
            const total = Number(this.dados[0]?.abertos) + Number(this.dados[0]?.naoAbertos);
            return formatarNumero(total);
        },
        quantidadeAbertos() {
            return formatarNumero(this.dados[0]?.abertos);
        },
        quantidadeNaoAbertos() {
            return formatarNumero(this.dados[0]?.naoAbertos);
        },
    },
    methods: {
        async load(params) {
            this.periodicidade = params.periodicidade;
            this.dataInicio = params.dataInicio;
            this.dataFim = params.dataFim;
            await this.queryData();
        },
        async queryData() {
            this.loading = true;
            try {
                const { data } = await getClientBase().get('/indicadores/envio-de-emails/prestadores/agendamentos-futuros', {
                    params: {
                        filtros: JSON.stringify({
                            periodicidade: this.periodicidade,
                            dataInicio: this.dataInicio,
                            dataFim: this.dataFim
                        })
                    }
                });
                this.dados = data;
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops!',
                    detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!',
                    life: 3000
                });
            }
            this.loading = false;
        }
    }
};
</script>

<style lang="scss" scoped>
:deep(.p-chart) {
    height: 32rem;
    aspect-ratio: 1/1;

    canvas {
        width: 100% !important;
        height: 100% !important;
        aspect-ratio: 1/1;
    }
}
</style>
