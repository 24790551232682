<template>
    <div>
        <HeaderCard :loading="loading" tittle="Solicitantes" @onLoad="load" />
        <div class="flex flex-column">
            <p class="text-7xl font-normal m-0 mt-4 justify-content-center" style="line-height: 38px">
                {{ dados[0]?.percentualAbertos == undefined || dados[0]?.percentualAbertos == '0.00' ? '0%' : dados[0]?.percentualAbertos + '%' }}
            </p>
            <div class="m-0" style="top: 150px">
                <p class="text-md font-normal mb- justify-content-center">E-mails abertos de solicitantes</p>
            </div>
        </div>
        <div class="flex" style="width: 100%">
            <Chart type="bar" :data="stackedData" :options="stackedOptions" class="h-28rem" />
            <div style="display: flex; flex-direction: column; justify-content: center; gap: 10px; margin: 0 0 0 20px">
                <div>
                    <span style="font-size: 30px; font-weight: bold">{{ quantidadeTotal }} </span>
                    <p>Total de e-mails</p>
                </div>
                <div style="display: flex; flex-direction: column">
                    <span style="font-size: 30px; font-weight: bold">{{ quantidadeAbertos }}</span>
                    <div style="display: flex; align-items: center; gap: 5px">
                        <div style="background: #9747ff; width: 10px; height: 10px" />
                        <p>Abertos</p>
                    </div>
                </div>
                <div style="display: flex; flex-direction: column">
                    <span style="font-size: 30px; font-weight: bold">{{ quantidadeNaoAbertos }}</span>
                    <div style="display: flex; align-items: center; gap: 5px">
                        <div style="background: #fab710; width: 10px; height: 10px" />
                        <p>Não abertos</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { getClientBase } from '@/services/http';
import HeaderCard from '../../components/HeaderCard.vue';
import formatarNumero from '@/utils/FormatadorNumero';

export default {
    data() {
        return {
            dados: [],
            periodicidade: null,
            dataInicio: null,
            dataFim: null,
            loading: false
        };
    },
    components: {
        HeaderCard
    },
    computed: {
        stackedData() {
            return {
                labels: [''],
                datasets: [
                    {
                        label: 'Abertos',
                        data: [this.dados[0]?.abertos],
                        backgroundColor: '#9747FF',
                        skipNull: true,
                        intersect: false
                    },
                    {
                        label: 'Não abertos',
                        data: [this.dados[0]?.naoAbertos],
                        backgroundColor: '#FAB710',
                        skipNull: true,
                        intersect: false
                    }
                ]
            };
        },
        stackedOptions() {
            return {
                maintainAspectRatio: false,
                aspectRatio: 0.8,
                interaction: {
                    mode: 'nearest'
                },
                legend: {
                    display: false
                },
                scales: {
                    x: {
                        ticks: {
                            color: '#495057',
                            font: {
                                weight: 500
                            }
                        },
                        grid: {
                            display: false,
                            drawBorder: false,
                            color: '#EBEDEF'
                        }
                    },
                    y: {
                        ticks: {
                            color: '#495057'
                        },
                        grid: {
                            color: '#EBEDEF',
                            drawBorder: false
                        }
                    }
                }
            };
        },
        quantidadeTotal() {
            const total = Number(this.dados[0]?.abertos) + Number(this.dados[0]?.naoAbertos);
            return formatarNumero(total);
        },
        quantidadeAbertos() {
            return formatarNumero(this.dados[0]?.abertos);
        },
        quantidadeNaoAbertos() {
            return formatarNumero(this.dados[0]?.naoAbertos);
        },
    },
    methods: {
        async load(params) {
            this.periodicidade = params.periodicidade;
            this.dataInicio = params.dataInicio;
            this.dataFim = params.dataFim;
            await this.queryData();
        },
        async queryData() {
            this.loading = true;
            try {
                const { data } = await getClientBase().get('/indicadores/envio-de-emails/solicitantes', {
                    params: {
                        filtros: JSON.stringify({
                            periodicidade: this.periodicidade,
                            dataInicio: this.dataInicio,
                            dataFim: this.dataFim
                        })
                    }
                });
                this.dados = data;
            } catch (error) {
                this.$toast.add({
                    severity: 'info',
                    summary: 'Ops!',
                    detail: 'Tivemos algum problema ao carregar os gráficos, por favor clique em recarregar!',
                    life: 3000
                });
            }
            this.loading = false;
        }
    }
};
</script>
