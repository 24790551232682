export default function formatarNumero(num, precisao = 1) {
    const map = [
        { sufixo: 'T', limite: 1e12 },
        { sufixo: 'B', limite: 1e9 },
        { sufixo: 'M', limite: 1e6 },
        { sufixo: 'K', limite: 1e3 },
        { sufixo: '', limite: 1 }
    ];

    const encontrado = map.find((x) => Math.abs(num) >= x.limite);
    if (encontrado) {
        const formatado = (num / encontrado.limite).toFixed(precisao);
        const formatadoSemPrecisao = (num / encontrado.limite).toFixed(0);
        const formatadoCheck = +formatado === +formatadoSemPrecisao;
        return formatadoCheck ? formatadoSemPrecisao + encontrado.sufixo : formatado + encontrado.sufixo;
    }

    return num;
}
